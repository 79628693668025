import React from "react";
import Spline from "@splinetool/react-spline";
import { Link } from "react-router-dom";
import styles from "./Home.module.css"; // Импортируем стили из модул

function Home() {
  return (
    <div className="container">
      <header className={styles.header}>
        <Link to="/" className={styles["logo-link"]}>
          <h1>MaxSimple</h1>
        </Link>
        <a href="https://crm.mxmp.ru/" target="_blank" className="btn">
          Войти
        </a>
      </header>

      <main className={styles.content}>
        <div className={styles["spline-scene"]}>
          <Spline scene="https://mxmp.ru/scene.splinecode" />
        </div>
        <div className={styles["text-section"]}>
          <h1>Испытайте нашу экосистему для вашего бизнеса</h1>
          <h2>
            Участвуйте в бета-тестировании. Создавайте свои приложения (сайты) и
            управляйте бизнесом бесплатно.
          </h2>
          <p>
            Присоединяйтесь к бета-тестированию нашего инновационного
            конструктора интернет-решений. Получите полноценный сайт для любого
            устройства, систему управления бизнесом. Ваш опыт взаимодействия
            поможет нам сделать продукт лучше!
          </p>
          <a href="https://t.me/maxsimple_ai" target="_blank" className="btn">Связаться в Telegram</a>
        </div>
      </main>

      <footer className={styles.footer}>
        <p>2025 © ООО «Максимпл»</p>
        <div className={styles.links}>
          <Link to="/terms" className={styles.link}>
            Условия и положения
          </Link>
          <Link to="/privacy" className={styles.link}>
            Политика конфиденциальности
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default Home;
